$(function () {
    var domainsWithOverlay = '.domains-container, #plein-overlay',
        header = '.page-header',
        mobileHeaderOverlay = '.page-header__main-overlay',
        active = 'active',
        totalNavigation = '.plein-navigation-desktop',
        triggers = '.general-navigation-trigger, .js-nav-toggle, .plein-specific-nav.pull-left > .pull-left > a',
        categoryTriggers = '.plein-specific-nav.pull-left > .pull-left > a',
        mobileTrigger = '.js-nav-toggle',
        hidden = 'hide',
        domainListContainer = '.domains-list-container',
        domainItem = '.domain-list-item.has-sub, .single-list-item',
        backToPrev = '.nav-prev',
        userAccount = '.account-menu',
        openUserAccount = '.js-user-menu',
        previousDomainItem = '',
        body = 'body',
        closeBtnMobileAndOverlay = '#close-navigation, #plein-overlay',
        $menu = $('.domains-container'),
        row = '',
        mouseLocs = [],
        delay = 100,
        delaySlow = 300,
        tolerance = 75,
        mouseLocsTracked = 3,
        isMobile = false,
        navStatus = false,
        isNavLoaded = false,
        activeRow = null,
        lastDelayLoc = null,
        userAccountTrigger = false,
        timeoutId = null,
        hashUrl = null,
        hashId = null,
        activateDomainItem = null,
        navActivator = null;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        delay = 0;
        delaySlow = 0;
        isMobile = true;
    }

    if(isMobile == false) {
        $(totalNavigation).on('mouseleave', function () {
            closeNavSlow();
        }).on('mouseenter', function () {
            closeNavSlow.cancel();
        });
    }

    $(triggers).on('click', function (e) {
        e.preventDefault();
        (navStatus == false)?showNav():closeNav();
        navActivator = e.target.id;
    });
    $(categoryTriggers).on('click',function (e) {
        e.preventDefault();
        if(navStatus === false) {
            navActivator = e.target.id;
            switchCategory(navActivator);
        }
    })
    $(triggers).on('mouseover', function (e) {
        e.preventDefault();
        if(navStatus === false) {
            return;
        }
        else {
            showNav();
            $('.domain-list-item.has-sub.active').removeClass('active');
            switchCategory(e.target.id);
        }
    });

    function switchCategory(cat) {
        switch(cat) {
            case "Verzorging" :
                if(document.getElementById('verzorging'))
                    document.getElementById('verzorging').classList.add('active');
                break;
            case "Beauty" :
                if(document.getElementById('beauty'))
                    document.getElementById('beauty').classList.add('active');
                break;
            case "Gezondheid" :
                if(document.getElementById('gezondheid'))
                    document.getElementById('gezondheid').classList.add('active');
                break;
            case "Eten" :
                if(document.getElementById('eten'))
                    document.getElementById('eten').classList.add('active');
                break;
            case "Baby" :
                if(document.getElementById('baby'))
                    document.getElementById('baby').classList.add('active');
                break;
            case "Huishouden" :
                if(document.getElementById('huishouden'))
                    document.getElementById('huishouden').classList.add('active');
                break;
            case "Huisdier" :
                if(document.getElementById('huisdier'))
                    document.getElementById('huisdier').classList.add('active');
                break;
            case "Cadeaus" :
                if(document.getElementById('speelgoed & cadeaus'))
                    document.getElementById('speelgoed & cadeaus').classList.add('active');
                break;
            default :
                return;
        }
    }

    function getTriggerSub(){

        if( $( mobileTrigger ).attr("href") ) {
            hashUrl = $( mobileTrigger ).attr("href");
        }
        else {
            hashUrl = $( mobileTrigger ).closest( "a" ).attr("href");
        }

        if(hashUrl.indexOf("#") !== -1) {
            hashId = hashUrl.split("#");
            if(hashId.length > 1) {
                var elementId = hashId[1];
                if(elementId != '') elementId = "#"+elementId;
                activateDomainItem = elementId;
                if(activateDomainItem) {
                    $(activateDomainItem).trigger("click");
                }
                return true;
            }
            else return false;
        }
    }

    $(closeBtnMobileAndOverlay).on('click', function () {
        closeNav();
        $(backToPrev).add(domainListContainer).add(domainItem).removeClass(active);
    });

    $(backToPrev).on('click', function () {
        $(domainListContainer).scrollTop(0);
        $(backToPrev).add(domainListContainer).add(domainItem).removeClass(active);
    });

    $(openUserAccount).on('click', function (e) {
        e.preventDefault();

        if (isNavLoaded == false) {
            userAccountTrigger = true;
            showNav(userAccountTrigger);
        } else {
            showNav();
            $(userAccount).add(backToPrev).add(domainListContainer).addClass(active);
        }
    });

    function openGroups(e) {
        (e != previousDomainItem)?$(previousDomainItem).removeClass(active):'';
        $(backToPrev).addClass(active);
        e.add(domainListContainer).addClass(active);
        $(domainListContainer).scrollTop(0);
        previousDomainItem = e;
    }

    function loadNavOnce(userAccountTrigger) {
        $.ajax({
            async: true,
            url: window.location.origin + '/megamenu',
        }).success(function( html ) {
            $(domainListContainer).html( html );
            isNavLoaded = true;
            switchCategory(navActivator);
            afterLoading();
            if (userAccountTrigger == true) {
                $(userAccount).add(backToPrev).add(domainListContainer).addClass(active);
            }
            else {
                getTriggerSub();
            }
        });
    }

    var mousemoveNavigation = function(e) {
        mouseLocs.push({x: e.pageX, y: e.pageY});

        if (mouseLocs.length > mouseLocsTracked) {
            mouseLocs.shift();
        }
    };

    var showNav = _.debounce(function (userAccountTrigger) {
        $(header).addClass('mobile-fixed');
        $(mobileHeaderOverlay).addClass('active');
        $('div.plein-navigation-desktop > div.container').css('overflow','visible');
        $(domainsWithOverlay).removeClass(hidden).addClass('animateNav active');
        $(triggers).addClass(active);
        getTriggerSub();
        (isNavLoaded == false)?loadNavOnce(userAccountTrigger):null;
        navStatus = true;

        if ($(document).width() >= 960) {
            $menu.find(domainItem).off('mouseenter', mouseenterDomain);
            $menu.find(domainItem).on('mouseenter', mouseenterDomain);
        }
        else {
            $menu.find(domainItem).off('mouseenter', mouseenterDomain);
            $(body).addClass('no-scroll');
            $(domainItem).on('click', function () {
                openGroups($(this));
            });
        }
    }, delay);

    var closeNav = _.debounce(function () {
        $(header).removeClass('mobile-fixed');
        $(mobileHeaderOverlay).removeClass('active');
        $('div.plein-navigation-desktop > div.container').css('overflow','hidden');
        $(body).removeClass('no-scroll');
        $(domainsWithOverlay).addClass(hidden).add(domainItem).removeClass('animateNav active');
        $(triggers).add(previousDomainItem).add(backToPrev).removeClass(active);
        getTriggerSub();
        navStatus = false;
        row = '';
        activeRow = '';
    }, delay);

    var closeNavSlow = _.debounce(function () {
        $(body).removeClass('no-scroll');
        $(mobileHeaderOverlay).removeClass('active');
        $(header).removeClass('mobile-fixed');
        $(domainsWithOverlay).addClass(hidden).add(domainItem).removeClass('animateNav active');
        $(triggers).add(previousDomainItem).removeClass(active);
        navStatus = false;
        row = '';
        activeRow = '';
    }, delaySlow);

    function mouseenterDomain () {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        possiblyActivateGroups(this);
    }

    var possiblyActivateGroups = function(row) {
        var delay = activationDelay();
        if (delay) {
            timeoutId = setTimeout(function() {
                possiblyActivateGroups(row);
            }, delay);
        } else {
            activateGroups(row);
        }
    };

    var activateGroups = function(row) {
        if (row == activeRow) {
            return;
        } else {
            ($(row) != previousDomainItem)?$(previousDomainItem).removeClass(active):'';
        }

        openGroups($(row));
        activeRow = row;
    };

    var activationDelay = function() {
        var offset = $menu.offset(),
            upperLeft = {
                x: offset.left,
                y: offset.top - tolerance
            },
            upperRight = {
                x: offset.left + $menu.outerWidth(),
                y: upperLeft.y
            },
            lowerLeft = {
                x: offset.left,
                y: offset.top + $menu.outerHeight() + tolerance
            },
            lowerRight = {
                x: offset.left + $menu.outerWidth(),
                y: lowerLeft.y
            },
            loc = mouseLocs[mouseLocs.length - 1],
            prevLoc = mouseLocs[0];

        if (!loc) {
            return 0;
        }

        if (!prevLoc) {
            prevLoc = loc;
        }

        if (prevLoc.x < offset.left || prevLoc.x > lowerRight.x ||
            prevLoc.y < offset.top || prevLoc.y > lowerRight.y) {
            // If the previous mouse location was outside of the entire
            // menu's bounds, immediately activate.
            return 0;
        }

        if (lastDelayLoc &&
            loc.x == lastDelayLoc.x && loc.y == lastDelayLoc.y) {
            // If the mouse hasn't moved since the last time we checked
            // for activation status, immediately activate.
            return 0;
        }

        function slope(a, b) {
            return (b.y - a.y) / (b.x - a.x);
        }

        var decreasingCorner = upperRight,
            increasingCorner = lowerRight;

        var decreasingSlope = slope(loc, decreasingCorner),
            increasingSlope = slope(loc, increasingCorner),
            prevDecreasingSlope = slope(prevLoc, decreasingCorner),
            prevIncreasingSlope = slope(prevLoc, increasingCorner);

        if (decreasingSlope < prevDecreasingSlope &&
            increasingSlope > prevIncreasingSlope) {
            // Mouse is moving from previous location towards the
            // currently activated submenu. Delay before activating a
            // new menu row, because user may be moving into submenu.
            lastDelayLoc = loc;
            return delaySlow;
        }

        lastDelayLoc = null;
        return 0;
    };

    var afterLoading = function () {
        if(isMobile == false) {
            $(document).mousemove(mousemoveNavigation);
            if ($(document).width() > 960) {
                $menu.find(domainItem).on('mouseenter', mouseenterDomain);
            }
            else {
                $menu.find(domainItem).off('mouseenter', mouseenterDomain);
                $(domainItem).on('click', function () {
                    openGroups($(this));
                });
            }
        } else {
            $(domainItem).on('click', function () {
                openGroups($(this));
            });
        }
    }
});
